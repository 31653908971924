import React from "react";

import styles from "./PageHeader.module.scss";

interface PageHeaderProps {
  children: React.ReactElement | Array<React.ReactElement>;
}

export default function PageHeader(props: PageHeaderProps) {
  return (
    <>
      <div className="primary-color p-0 md:p-5 flex justify-center items-center full-vw border-b border-gray-200 w-full">
        {props.children}
      </div>
    </>
  );
}
