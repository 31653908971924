import React, { FunctionComponent as FC } from "react";
import styles from "./ExploreButton.module.scss";
import { useRouter } from "next/router";
import { useI18n } from "@/i18n/hook";
import path from "@/server/path";
import Link from "next/link";
interface ExploreButtonProps {
  children?: React.ReactElement;
}
const ExploreButton: FC<ExploreButtonProps> = (props: ExploreButtonProps) => {
  const router = useRouter();
  const i18n = useI18n();
  const isExplore = router.pathname.endsWith(path.explore);

  return (
    <span className={`${isExplore ? "border-b-2 border-blue-700" : ""}`}>
      <Link href={path.explore}>
        <a
          className={`${
            isExplore ? "text-blue-700" : ""
          } flex items-center pointer tracking-tight`}
        >
          <i className="material-icons-outlined">travel_explore</i>
          <span>{i18n.t("navbar/explore")}</span>
        </a>
      </Link>
    </span>
  );
};

export default ExploreButton;
