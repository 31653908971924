import { useI18n } from "@/i18n/hook";
import Link from "next/link";

import { getLogger } from "@/log";
import { useSession } from "@/nextlib/context";
import path from "@/server/path";
import ExploreButton from "./ExploreButton";
import Logo from "./Logo";
import LogoBar from "./LogoBar";
import Searchbar from "./Searchbar";

const log = getLogger(__filename);

interface NavbarProps {}

export default function Navbar(props: NavbarProps) {
  return (
    <div className="text-xs sm:text-sm md:text-base box-border py-1 px-3 md:p-0 flex flex-col items-center w-100v md:w-80v lg:w-85v">
      <LogoBar />
      <div className="flex items-center md:justify-between w-full">
        <div className="flex items-center w-full md:w-auto justify-around md:justify-start">
          <div className="hidden md:flex">
            <Logo />
          </div>
          <Searchbar />
        </div>

        <div className="hidden md:flex items-center justify-around">
          <RightSideNavbar></RightSideNavbar>
        </div>
      </div>
    </div>
  );
}

function RightSideNavbar(props: any) {
  const i18n = useI18n();
  const session = useSession();

  if (!session?.email) {
    return (
      <div className="flex gap-5 items-center">
        <ExploreButton></ExploreButton>
        <Link href={path.signin}>
          <a className={"pointer text-center btn-create"}>
            {/* TODO: update message */}
            <span>{i18n.t("navbar/signup")}</span>
          </a>
        </Link>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-5">
      <ExploreButton></ExploreButton>
      <Link href={path.account2("convo")}>
        <a className="flex items-center" href="">
          <i className={"material-icons-outlined"}>chat</i>
        </a>
      </Link>
      <Link href={path.account}>
        <a
          className={
            "bg-white border rounded-full py-1 md:py-2 px-4 flex items-center gap-1 "
          }
        >
          <i className="material-icons-outlined">account_circle</i>
          <span className="hidden md:block">{i18n.t("navbar/account")}</span>
        </a>
      </Link>
    </div>
  );
}
