import FilterToolbar from "@/comp/FilterToolbar";
import Navbar from "@/comp/Navbar";
import PageContent from "@/comp/PageContent";
import PageHeader from "@/comp/PageHeader";

import RealtimeTrend from "@/comp/RealTimeTrend";

interface TrendProps {}

export default function Explore() {
  return (
    <>
      <PageHeader>
        <Navbar></Navbar>
      </PageHeader>
      <PageContent>
        <Trend></Trend>
      </PageContent>
    </>
  );
}

export function Trend(props: TrendProps) {
  return (
    <>
      <div className="col-span-12">
        <div className="mt-5">
          <FilterToolbar hidePersonaFilter personaFilterStaticItems={[]} />
        </div>
      </div>
      <div className="col-span-12 md:col-span-6">
        <RealtimeTrend showTitle by="topicsByPersona"></RealtimeTrend>
      </div>
      <hr className="my-5 md:hidden" />
      <div className="col-span-12 md:col-span-6">
        <RealtimeTrend showTitle by="personasByTopic"></RealtimeTrend>
      </div>
    </>
  );
}
