import { useI18n } from "@/i18n/hook";
import path from "@/server/path";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FunctionComponent as FC } from "react";
import ExploreButton from "./ExploreButton";
import Logo from "./Logo";

interface LogoBarProps {
  children?: React.ReactElement;
}
const LogoBar: FC<LogoBarProps> = (props: LogoBarProps) => {
  const router = useRouter();
  const i18n = useI18n();

  const isExplore = router.pathname.endsWith(path.explore);

  return (
    <div
      className={`shadow-sm flex items-center justify-between rounded-full md:hidden w-full`}
    >
      <div className="flex items-start">
        <ExploreButton></ExploreButton>
      </div>
      <Logo></Logo>
      <Link href={path.account}>
        <a
          className={
            "bg-white border rounded-full py-1 md:py-2 px-4 flex items-center gap-1 "
          }
        >
          <i className="material-icons-outlined">account_circle</i>
          <span className="hidden md:block">{i18n.t("navbar/account")}</span>
        </a>
      </Link>
    </div>
  );
};

export default LogoBar;
